/*responsive desighn*/

@media only screen and (max-width: 600px) {
  .TextCont {
    display: none;
  }

  .ImgCont {
    width: 100% !important;
    text-align: center !important;
  }
}

.trustedMechCarousel {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 70px;
}

.carouselCont {
  /* background-color: #f1f2ed; */
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.ImgCont {
  float: left !important;
  width: 50%;
}

.TextCont {
  padding: 1% !important;
  position: relative;
  height: 50%;
  right: 4%;
  width: 50%;
  font-size: 25px;
}

.carousel-caption {
  position: relative !important;
  top: 0 !important;
  height: 100% !important;
  width: 75% !important;
}

.TextCont p {
  color: black !important;
  font-family: "Space Grotesk", sans-serif;
}

.TextCont h3 {
  color: black !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-control-next-icon:after {
  content: ">";
  font-size: 30px;
  color: #1e3d84;
  font-weight: 900;
}

.carousel-control-prev-icon:after {
  content: "<";
  font-size: 30px;
  color: #1e3d84;
  font-weight: 900;
}

.carousel-indicators li {
  background-color: #ffdd0080;
}

.carousel-indicators .active {
  background-color: #ffdd00;
}

.carouselCont .d-none {
  display: block !important;
}

.cta-btn {
  position: relative;
  right: 440px;
  bottom: 90px;
  background-color: var(--blueTG);
  padding: 10px;
  width: 20%;
  border-radius: 50px;
  text-align: center;
}

.cta-btn a {
  color: #fff;
  padding: 10px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .carouselCont {
    margin: 0px 20px;
  }

  .TextCont {
    display: block !important;
    width: 100%;
  }

  .TextCont .carousel-caption {
    display: block !important;
  }

  .TextCont .carousel-caption p {
    font-size: 15px;
    text-align: left;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 50px;
    width: 50px;
    outline: black;
    background-size: 100%, 100%;
    background-image: none;
  }

  .carousel-control-next-icon:after {
    font-size: 15px;
  }

  .carousel-control-prev-icon:after {
    font-size: 15px;
  }

  .ImgCont img {
    width: 100%;
  }

  .cta-btn {
    position: relative;
    right: 0px;
    bottom: 18px;
    width: 100%;
    text-align: center;
    padding: 8px;
  }

  .cta-btn a {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1024px) {
  .cta-btn {
    text-align: center;
    right: 520px;
  }
}
