.btn:focus {
  box-shadow: none;
}

.te-accordion-header {
  font-weight: 700;
  font-size: 1.2rem;
  text-decoration: none !important;
  white-space: normal;
  cursor: pointer;
  color: #1d2b4f !important;
}

.card-header h5 button i:before,
.card-header h5 button i:after {
  transition: all 0.3s;
}
.card-header h5 button i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 15px;
  right: 40px;
}
.card-header h5 button i:before,
h5 button i:after {
  content: "";
  position: absolute;
  background-color: #e8b800;
  width: 3px;
  height: 9px;
}
.card-header h5 button .is-open:before {
  transform: translate(-2px, 0) rotate(45deg);
}
.card-header h5 button .is-open:after {
  transform: translate(2px, 0) rotate(-45deg);
}
.card-header h5 button .not-open:before {
  transform: translate(2px, 0) rotate(45deg);
}
.card-header h5 button .not-open:after {
  transform: translate(-2px, 0) rotate(-45deg);
}

.card-body p {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 20px;
  color: #3d5a80;
}

.card-body ul {
  margin-left: 2.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 20px;
  color: #3d5a80;
}

.card-body h6 {
  font-weight: bold;
  color: #3d5a80;
}

@media only screen and (max-width: 600px) {
  .card-header h5 button i {
    display: none;
  }
}
