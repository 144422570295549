@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;700&display=swap");

* {
  font-family: "Space Grotesk";
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 26px;
  text-align: center;
  color: #1d2b4f;
  margin-bottom: 20px;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 20px;
  color: #3d5a80;
}

.subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 18px;
  color: #1d2b4f;
}

.presentation img {
  width: 450px;
}

.modal-init .modal-content {
  background-color: transparent !important;
  border: none;
}

.modal-init .modal-body {
  background-color: transparent !important;
}
