.balance-amount {
  font-size: 2rem;
  font-weight: bold;
}

.balances-container {
  justify-content: center;
  flex-wrap: wrap;
}

.balances-body .balance {
  margin: 0;
}

@media only screen and (max-width: 1400px) {
  .balances-body .balance {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .balances-body {
    padding: 18px !important;
  }

  .balances-body .balance {
    font-size: 16px;
  }

  .platesub {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1000px) {
  .balances-body {
    padding: 10px !important;
  }

  .balances-body .balance {
    font-size: 12px;
  }

  .platesub {
    font-size: 12px;
  }
}

.tolls-img {
  height: 60px;
  position: relative;
  right: 211px;
  bottom: 50px;
}

@media only screen and (max-width: 600px) {
  .balances-body .balance {
    font-size: 10px;
  }

  .tolls-img {
    height: 60px;
    position: relative;
    right: 150px;
    bottom: 50px;
  }
}

/* Responsive for laptops */
@media (max-width: 1440px) {
  .tolls-img {
    right: 150px
  }
}