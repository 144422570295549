.report-table {
  border: 1px solid var(--lightgreey);
  background-color: #fff;
  width: 100%;
}

.report-table thead {
  color: var(--blueTG);
  font-size: 1.2rem;
}

.report-table th {
  padding: 0.1rem;
  border: 1px solid #fff;
  position: -webkit-sticky;
  position: sticky;
  background-color: var(--yellow);
  top: 0;
  z-index: 10;
}

.report-table tbody {
  border: 1px solid var(--lightgreey);
  font-size: 0.9rem;
  color: var(--grey);
}

.report-table td {
  padding: 1rem;
  border: 1px solid var(--lightgreey);
}

/*Transacciones list detail*/
.transacc-tbl {
  margin-top: 1rem;
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
  text-align: center;
  width: 100%;
}

.transacc-tbl tr:nth-child(odd) {
  background-color: #f7f7f7;
}

.transacc-tbl td {
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
  padding: 1px 1rem;
}

@media only screen and (max-width: 600px) {
  .report-table {
    font-size: 0.7rem;
  }

  .report-table th {
    font-size: 0.8rem;
  }

  .report-table td {
    font-size: 0.7rem;
  }

  .transacc-tbl {
    font-size: 0.9rem;
  }

  .transacc-tbl tr {
    display: grid;
    border-top: 1px solid var(--grey);
    border-bottom: 1px solid var(--grey);
  }

  .transacc-tbl td {
    border: none;
  }
}
