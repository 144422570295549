.te-container-contact {
  display: flex;
  flex-direction: column;
}

.te-contact-map {
  height: 200px;
}

.te-contact-info {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../../../../Assets/BANNER-ROULETTE2.png");
  background-color: #000;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  width: 100%;
  height: 350px;
  padding: 20px;
}

.te-contact-info>h3 {
  color: #fff;
}

.te-contact-info>p {
  color: #fff;
}

@media (min-width: 1025px) {
  .te-container-contact {
    flex-direction: row;
    height: 400px;
  }

  .te-contact-map {
    height: 100%;
  }

  .te-contact-info {
    height: 100%;
    padding: 40px;
  }
}