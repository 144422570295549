.row-puntos-ventas {
  display: flex;
  overflow-x: scroll;
  /* animation: cambio 13s infinite alternate linear; */
}

.punto-venta {
  width: 1000px;
}

.brandImg h1 {
  text-align: center;
}

.text {
  margin-top: 20px;
  font-size: 19px;
}

.serviceText {
  font-size: 16px;
  text-align: center;
}

.brandImg .rounded {
  height: 140px;
  max-width: 270px;
  object-fit: fill;
}

.simple-card {
  transform: none !important;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 83.5% !important;
  }

  [class*="col-"] {
    width: 100%;
  }

  .puntos-ventas .container-main {
    display: none;
  }

  .puntos-ventas-mobile .container-main {
    display: block;
  }

  .puntos-ventas-mobile .container-main .container-slideshow {
    overflow-x: scroll;
  }
}