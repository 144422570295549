.te-container-footer {
  display: grid;
}

.footer-left img {
  width: 20rem;
}

.footer-left {
  text-align: center;
}

.footer-rigth {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: baseline;
  gap: 20px;
}

.links ul {
  list-style: none;
}

.links ul>li {
  margin-bottom: 0.4rem;
}

.links ul li>a {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  color: #77838f;

  text-decoration: none;
}

.links ul li>a:hover {
  color: #1e2022;
}

.contact>p {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  color: #77838f;
  margin-bottom: 0.4rem;
}

.copyright {
  font-style: normal;
  font-weight: 300;
  font-size: 0.7rem;
  text-align: center;
  color: #77838f;
}

.icons {
  display: flex;
  justify-content: center;
}

.social-icons {
  background: #e8b800;
  border-radius: 50%;
  margin: 0.5rem 0.2rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icons:hover {
  text-decoration: none;
  background: #e8b80080;
}

.social-icons i {
  color: white !important;
  font-size: 1.6rem;
  margin: 0.5rem 0rem;
}

@media (min-width: 1025px) {
  .te-container-footer {
    grid-template-columns: 1fr 2fr;
  }

  .footer-left {
    text-align: start;
  }

  .footer-rigth {
    justify-content: space-evenly;
    flex-direction: row;
  }

  .links ul>li {
    margin-bottom: 0.6rem;
  }

  .contact>p {
    margin-bottom: 0.6rem;
  }
}

@media (max-width: 1440px) {
  .social-icons i {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 1024px) {
  .social-icons i {
    font-size: 1.2rem;
  }
}