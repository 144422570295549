.title2 {
    font-size: 1.5rem;
    font-family: 'Space Grotesk', sans-serif;
    font-weight: bold;
}

.sub-text {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 20px;
}

.general-info-btn {
    width: 300px;
    height: 50px;
    border-radius: 15px;
    font-size: 17px;
    background-color: #04216d;
    color: #fff;
    border: none;
    outline: none;
    text-align: left;
    padding: 10px;
}

.general-info-img {
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    height: 330px;
    cursor: pointer;
}

/* Iframe */
.container-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 600px;
    /* padding-top: 56.25%; */
    padding-top: 41.25%;
}

.responsive-iframe body img {
    height: 500px;
}



/* Vertical line */
.vl {
    border-left: 5px solid rgb(232, 184, 0, .5);
    height: 390px;
    position: absolute;
    left: 90%;
    bottom: -10px;
}

.vl1 {
    border-left: 5px solid rgb(232, 184, 0);
    height: 60px;
    position: absolute;
    left: 90%;
    bottom: 230px;
}

.vl2 {
    border-left: 5px solid rgb(232, 184, 0);
    height: 60px;
    position: absolute;
    left: 90%;
    bottom: 162px;
}

.vl3 {
    border-left: 5px solid rgb(232, 184, 0);
    height: 60px;
    position: absolute;
    left: 90%;
    bottom: 95px;
}

/* Responsive */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.play-button {
    height: 50px;
    position: relative;
    left: 200px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .vl {
        display: none;
    }

    .general-info-img {
        height: 250px;
    }

    .img-container {
        margin-top: 15px;
    }

    .vl1 {
        display: none;

    }

    .vl2 {
        display: none;

    }

    .vl3 {
        display: none;
    }
}