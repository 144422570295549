/*A link that moves a bit to the top when hovered*/
.hov-link:hover {
  transform: translateY(-2px);
  backface-visibility: hidden;
  transition: all 0.2s;
}

.link:link,
:visited {
  color: #000;
  font-weight: 600;
  text-decoration: none;
}
.link:hover {
  color: var(--yellow) !important;
  font-weight: bold;
  cursor: pointer;
}
