.modal-header {
  background: #F5F5F5;
  font-size: 1.2rem;
}

.modal-body{
  background-color: #f0f0f0;
}

.modal.card-title{
  margin-bottom: 0;
}

.modal-inner-card {
  border: 1px solid #d1d0d0;
  border-radius: 5px;
  font-size: 1.1rem;
  text-align: center;
}

#confirmModal .modal-content {
  border: 3px solid #457B9A !important;
}

#cardAzul{
  background-color: #162C50 !important;
  color: #fff;
}