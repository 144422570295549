.te-card {
    background: #ffffff;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.te-icon img {
    width: 50px;
}

.check-cert img {
    width: 30px;
    position: relative;
    left: 420px;
    bottom: 70px;
}

@media only screen and (max-width: 600px) {
    .check-cert img {
        width: 25px;
        left: 265px;
        bottom: 73px;
    }
}