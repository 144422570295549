.page-title {
  font-size: 2rem;
  color: var(--blueTG);
}

.menu-text {
  text-decoration: none !important;
}

.imagenes-container {
  display: flex;
  justify-content: space-around;
}

.img-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.img-container img {
  width: 200px;
}

.edit-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.file-preview {
  margin: 1rem;
}

.file-preview img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}
