#html5qr-code-full-region {
  width: 100%;
  margin: 0;
  padding: 3em;
  border-top: 4px solid #ffdd00;
  border-bottom: 4px solid #ffdd00;
  border-radius: 5px;
  -webkit-box-shadow: 0px 4px 13px -9px rgba(0, 0, 0, 0.77);
  box-shadow: 0px 4px 13px -9px rgba(0, 0, 0, 0.77);
  background-color: #fff;
  border: none !important;
}

#html5qr-code-full-region img[alt="Info icon"] {
  display: none !important;
}
#html5-qrcode-button-camera-permission {
  text-indent: -9999px;
  line-height: 0;
  margin-bottom: 10px;
}
#html5-qrcode-button-camera-permission:after {
  content: "Solicitar permiso de cámara";
  text-indent: 0;
  display: block;
  line-height: initial;
}
#html5-qrcode-anchor-scan-type-change {
  font-size: 0;
}
#html5-qrcode-anchor-scan-type-change:after {
  font-size: 1rem;
  content: "Cambiar el tipo de escaneado";
  cursor: pointer;
}
#html5-qrcode-button-file-selection {
  text-indent: -9999px;
  line-height: 0;
  margin-bottom: 0 !important;
  background-color: #fff;
  border: none;
  text-align: center;
  text-decoration: none;
  padding: 13px 30px;
  display: inline-block;
  border-radius: 10px;
  transition: all 0.2s;
  margin-bottom: 10px;
  text-decoration: underline;
}
#html5-qrcode-button-file-selection:after {
  content: "Seleccionar archivo";
  text-indent: 0;
  display: block;
  line-height: initial;
}

#html5qr-code-full-region__dashboard_section
  div:first-of-type
  div:last-of-type
  div {
  text-indent: -9999px;
  line-height: 0;
}

#html5qr-code-full-region__dashboard_section
  div:first-of-type
  div:last-of-type
  div:after {
  content: "Puedes arrastrar y soltar la foto.";
  text-indent: 0;
  display: block;
  line-height: initial;
}

#html5qr-code-full-region__header_message {
  text-indent: -9999px;
  line-height: 0;
}
#html5qr-code-full-region__header_message:after {
  content: "El código QR no es legible en la foto cargada, por favor, recórtelo y cárguelo.";
  text-indent: 0;
  display: block;
  line-height: initial;
}

#html5-qrcode-button-camera-start {
  text-indent: -9999px;
  line-height: 0;
  margin-bottom: 0 !important;
  background-color: var(--yellow);
  color: var(--blueTG);
  border: none;
  text-align: center;
  text-decoration: none;
  padding: 13px 30px;
  display: inline-block;
  border-radius: 10px;
  transition: all 0.2s;
  margin-bottom: 10px;
}

#html5-qrcode-button-camera-start:after {
  content: "Iniciar escaneo";
  text-indent: 0;
  display: block;
  line-height: initial;
}

#html5-qrcode-button-camera-stop {
  text-indent: -9999px;
  line-height: 0;
  margin-bottom: 0 !important;
  background-color: var(--yellow);
  color: var(--blueTG);
  border: none;
  text-align: center;
  text-decoration: none;
  padding: 13px 30px;
  display: inline-block;
  border-radius: 10px;
  transition: all 0.2s;
}

#html5-qrcode-button-camera-stop:after {
  content: "Parar escaneo";
  text-indent: 0;
  display: block;
  line-height: initial;
}
