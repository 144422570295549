.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}
.faq img {
  width: 400px;
}
.faq .react-reveal {
  width: 100%;
}

.faq .search {
  border-radius: 5px;
  height: 40px;
  width: 500px;
  padding: 10px;
  border: 1px solid #3d5a80;
}
