.te-container-gestion {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  margin-bottom: 15px;
}

.react-reveal {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #9595c3;
}

.numbersText {
  font-style: normal;
  font-weight: 700;
  font-size: 20px !important;
  letter-spacing: 0.8px;
  color: #e8b800;
}

.counter {
  display: table-cell;
  margin: 1.5%;
  font-size: 50px;
  background-color: #ff6f6f;
  width: 200px;
  border-radius: 50%;
  height: 200px;
  vertical-align: middle;
}

.te-card {
  background: #ffffff;
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.border-card {
  border-top: 2px solid var(--yellow)
}

.te-icon img {
  width: 50px;
}

@media (min-width: 1025px) {
  .about {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
  }

  .title {
    text-align: left;
  }

  .te-container-gestion {
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    text-align: left;
  }

  .numbersText {
    font-size: 30px !important;
    text-align: left !important;
  }

  .te-container-card {
    display: flex;
    flex-direction: column;
  }

  .te-card {
    padding: 20px;
    gap: 20px;
  }

  .te-icon img {
    width: 80px;
  }
}