.info-cards__wrapper [class^="col-"] {
  margin-top: 10px;
}

.info-card {
  border-radius: 15px;
  border: 1px solid #e8e7e7;
  border-top: 4px solid var(--yellow);
  padding: 2rem 1.2rem;
  height: 100%;
  color: var(--blueTG);
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.info-card .card-img {
  text-align: center;
  height: 400px;
  display: flex;
  justify-content: center;
}

.info-card .card-img img {
  width: 400px;
}

.info-card .card-info-body {
  padding: 0px;
}

.info-card__title {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 35px;
}

.info-card__subtitle {
  margin-top: 1rem;
  line-height: 1.1rem;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.info-card__content {
  font-size: 0.9rem;
  line-height: 1.1rem;
  color: #000;
  text-align: left;
}

.stores-img {
  height: 30px;
  margin-right: 5px;
}

.phone-stores a {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .info-card img {
    width: 40%;
  }
}
