.container-main {
  position: relative;
  display: flex;
  overflow: hidden;
}

.container-slideshow {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  width: 100%;
}

.controls {
  z-index: 20;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.controls-button-right {
  pointer-events: all;
  padding: 10px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  position: absolute;
  transition: 0.3s ease all;
  right: 0;
}

.controls-button-right path {
  filter: drop-shadow(-2px 0px 0px #fff);
}

.controls-button-left {
  pointer-events: all;
  padding: 10px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  position: absolute;
  transition: 0.3s ease all;
  left: 0;
}

.controls-button-left path {
  filter: drop-shadow(2px 0px 0px #fff);
}
