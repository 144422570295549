#goUpButton{
  position: fixed;
  right: 30px;
  bottom: 85px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  background-color: var(--yellow);
  opacity: 0.8;
  text-align: center;
}

#goUpButton:hover{
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0,0,0,.2);
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,.2);
}