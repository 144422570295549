.container-banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner {
  height: 600px;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}

.text-banner {
  position: absolute;
  color: #ffffff;
}

.indicador {
  position: absolute;
  width: 150px;
  height: 5px;
  margin-top: 50px;
  background: #fff;
  border-radius: 20px;
}
