.parallax {
  /* The image used */
  background: url("../../../../../Assets/what-is-tagexpress.png");

  /* Set a specific height */
  height: 600px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #1c3c86;
  background-size: contain;
}

@media only screen and (max-width: 700px) {
  .parallax {
    height: 200px;
    background-size: contain;
  }
}