.recharge-point {
  background-color: #f7fafd;
  padding: 30px 0px;
}
.point {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0px;
}

.point img {
  width: 100px;
}

@media (min-width: 1025px) {
  .point img {
    width: 250px;
  }
}
