#nav {
  z-index: 100;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 1s;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;
}

#nav a {
  color: rgba(0, 0, 0, 0.7) !important;
  text-decoration: none !important;
}

.nav-item {
  font-weight: 700;
}

.navbar-nav {
  margin-left: 40px !important;
}

.navScrolled {
  background-color: rgb(246, 245, 245, 0.97) !important;
  transition: all 1s;
}

.navDropdown {
  padding: 1rem;
  background-color: var(--lightGray);
  display: flex;
  align-items: center;
  flex-flow: column;
}

.logoNav {
  max-width: 200px;
}

.loginNavItem {
  color: rgba(0, 0, 0, 0.5);
  list-style: none;
}

.btn-access {
  border: none;
  text-align: center;
  text-decoration: none;
  padding: 8px 40px;
  display: inline-block;
  border-radius: 20px;
  background: linear-gradient(to right, #1e3d84 0%, #e8b800 100%);
  color: #fff;
  box-shadow: 0px 16px 22px -7px #002075;
}

.navbar-nav .nav-item .hov-link label {
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

/*responsive*/
@media only screen and (max-width: 600px) {
  #nav {
    margin: 0;
    padding: 10px 1rem;
    background-color: var(--lightGray);
  }

  .centerLogo {
    margin: 0 auto;
    width: 100%;
  }

  .logoNav {
    height: auto;
  }

  .loginNavItem {
    text-align: center;
  }

  .navbar-nav {
    margin-left: 0px !important;
  }
}
