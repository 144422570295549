.download-app {
  margin: 1rem 0px;
  background-color: #e8b800;
}

.te-container-download-app {
  background-image: url("../../../../../Assets/bg-download-app.png");
  background-color: #000;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr;
}

.download-app-info {
  width: 100%;
}

.download-app-store {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 1rem 0rem;
}

.download-app-store img {
  width: 8rem;
}

.download-app-info .subtitle {
  text-align: center;
  margin-top: 2.5rem;
}

.services-item {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.services-card {
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
}

.services-card img {
  width: 2.8rem;
}

.services-item h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #1d2b4f;
}

@media (min-width: 1025px) {
  .te-container-download-app {
    padding: 3rem;
    grid-template-columns: 1fr 1fr;
  }

  .download-app-mobile {
    display: flex;
    justify-content: center;
  }

  .download-app-mobile img {
    width: 20rem;
  }

  .download-app-store {
    margin: 2rem 0rem;
  }

  .download-app-store img {
    width: 10rem;
  }
}