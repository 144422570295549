.simple-card-profile {
  /*expandable*/
  width: 100%;
  margin: 0;
  padding: 3em;
  border-top: 4px solid #ffdd00;
  border-bottom: 4px solid #ffdd00;
  border-radius: 5px;
  -webkit-box-shadow: 0px 4px 13px -9px rgba(0, 0, 0, 0.77);
  box-shadow: 0px 4px 13px -9px rgba(0, 0, 0, 0.77);
  background-color: #fff;
}

.profile-data {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  text-align: center;
}

.profile-invoice {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.avatar {
  height: 100px;
  width: 100px;
  background-color: #ffdd00;
  border-radius: 50%;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.info-icon {
  font-size: 50px;
}

.info h3 {
  font-size: 25px;
}

.info .email {
  font-size: 20px;
  color: #adb5bd;
  font-weight: 200;
  margin-bottom: 10px;
}

.info .data {
  font-size: 16px;
  color: #adb5bd;
  font-weight: 400;
  margin-bottom: 10px;
}