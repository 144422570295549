#notFound.wrapper{
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  color: rgb(214, 214, 214);
  display: flex;
  justify-content: center;
  flex-flow: column;
}

#notFound .content{
  margin: 0 auto;
  width: auto;
  display: flex;
  justify-content: center;
  flex-flow: column;
  background-color: white;
  border-radius: 0.3rem;
  padding: 1rem 3rem;
}

#notFound .content__top{
  font-size: 3rem;
  padding: 0;
  color: #000;
}

#notFound .content__bottom{
  font-size: 1.4rem;
  color: #000;
}

#notFound .content__link{
  margin-top: 1rem;
  font-size: 0.8rem;
}

#notFound .content__link--text{
  text-decoration: underline;
  cursor: pointer;
  color: #000;
  font-size: 1.5rem;
}
.content__title{
  width: 75%;
  margin: auto;
  font-size: 25px;
  color: black;
}
.content__image{
  width: 600px;
  margin: 0;
  padding: 0;
}

/*Shrink font size especially*/
@media only screen and (max-width: 600px) {
  #notFound .content__top{
    font-size: 3rem;
  }
  
  #notFound .content{
    width: 90%;
  }

  #notFound .content__bottom{
    font-size: 1rem;
  }
  .content__title{
    width: 100%;
    font-size: 20px;
    color: black;
  }
  .content__image{
    width: 250px;
  }
  #notFound .content__link--text{
    font-size: 1.0rem;
  }
}