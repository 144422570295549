.simple-card {
  display: inline-block;
  margin:0;
  padding: 1em 0.8em;
  border-top: 4px solid #ffdd00;
  border-bottom: 4px solid #ffdd00;
  border-radius: 5px;
  text-align: center;
  -webkit-box-shadow: 0px 4px 13px -9px rgba(0,0,0,0.77); 
  box-shadow: 0px 4px 13px -9px rgba(0,0,0,0.77);
}

.simple-card:hover{
  -webkit-box-shadow: 0px 4px 20px -9px rgba(0,0,0,0.77); 
  box-shadow: 0px 4px 20px -9px rgba(0,0,0,0.77);
  backface-visibility: hidden;
  transition: all .2s;
}

.simple-card-exp { /*expandable*/
  margin:0;
  padding: 1.5em 3em;
  border-top: 4px solid #ffdd00;
  border-bottom: 4px solid #ffdd00;
  border-radius: 5px;
  text-align: center;
  -webkit-box-shadow: 0px 4px 13px -9px rgba(0,0,0,0.77); 
  box-shadow: 0px 4px 13px -9px rgba(0,0,0,0.77);
  background-color: #fff;
}

.simple-card-exp:hover{
  -webkit-box-shadow: 0px 4px 20px -9px rgba(0,0,0,0.77); 
  box-shadow: 0px 4px 20px -9px rgba(0,0,0,0.77);
  backface-visibility: hidden;
  transition: all .2s;
}

/*Color must be set manually. Default is white*/
.section-container{
 width: 100vw !important;
 left: 0;
 background-color: #000;
}

.rounded{
  border-radius: 5px;
}

.parallax-top-wrapper{
  position: relative;
  -webkit-box-shadow: 0 20px 100px -30px rgba(0,0,0,0.77); 
  box-shadow: 0 20px 100px -30px rgba(0,0,0,0.77);
}

.screen-container{
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
}

@media only screen and (max-width: 600px){
  .parallax-top-wrapper {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .simple-card{
    transform: scale(.9);
  }
} 