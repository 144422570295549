.passwdForm {
  width: 40%;
  margin: 0 auto;
}

.center-card {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {
  .passwdForm {
    width: 100%;
    margin: 0 auto;
  }
}