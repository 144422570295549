.image-login img {
  width: 100%;
  height: 100vh;
}

.login-card {
  margin: 0;
  padding: 3rem 5rem;
  width: 50%;
  border-radius: 5px;
  text-align: center;
  -webkit-box-shadow: 0px 4px 13px -9px rgba(0, 0, 0, 0.77);
  box-shadow: 0px 4px 13px -9px rgba(0, 0, 0, 0.77);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-top: 4px solid #ffdd00;
  background-color: #ffffff;
}

.grid-tips {
  margin-top: 25px;
}

.tips {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ty-text-description {
  font-size: 12px;
  text-align: start;
  width: 300px;
}

.tip-circle-login {
  float: left;
  margin-right: 15px;
  border-radius: 50%;
  background-color: #fecc17;
  height: 40px;
  width: 40px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marginTips {
  margin: 0 auto;
}

.login-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.btn-login {
  width: 100% !important;
}

.login-wrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: #f9f9ed;
}

.login-logo-tag img {
  max-width: 300px;
}

.login-logo-tag img {
  max-width: 300px;
}

.login-modal-form {
  background-color: #ffffff !important;
  padding: 40px;
  border-bottom: 5px solid var(--yellow);
}

.modal-title {
  width: 100%;
  text-align: center;
}

.login-side {
  background-color: #1C3C86;
}

.show-password {
  float: right;
  position: relative;
  left: 30px;
  bottom: 30px;
}

.forgot-password {
  font-size: 13px;
}

@media only screen and (max-width: 600px) {
  .login-card {
    transform: translate(-50%, -180%);
    opacity: 0.95;
    background-color: #ffffff;
    padding: 3rem 2rem;
    width: 70%;
    margin-top: 48rem;
    font-size: 15px;
  }

  .login-card-services {
    display: none;
  }

  .grid-tips {
    margin-top: 60rem;
  }

  /* .login-title{
        display: none;
      } */

  .login-logo-tag {
    text-align: center;
    margin-top: 7rem;
  }

  .login-logo-tag img {
    max-width: 200px;
    transform: translate(0%, -130%);
    background: white;
    opacity: 0.9;
  }

  .image-login {
    display: none;
  }

  .login-title {
    display: none;
  }

  .grid-tips {
    margin-top: -2rem;
    grid-row-gap: 0px;
  }

  .login-card-services {
    position: relative;
    top: 340px;
  }

  .tip-circle-login {
    height: 40px;
    width: 50px;
  }

  .ty-text-description {
    font-size: 15px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 395px) {
  .login-logo-tag {
    text-align: center;
    margin-top: 8rem;
  }

  .login-card {
    margin-top: 48rem;
    font-size: 12px;
  }

  .tip-circle-login {
    height: 40px;
    width: 73px;
  }

  .login-card-services {
    top: 460px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 375px) {
  .login-logo-tag {
    text-align: center;
    margin-top: 8rem;
  }

  .login-card {
    margin-top: 48rem;
    font-size: 11px;
  }

  .tip-circle-login {
    height: 40px;
    width: 73px;
  }

  .login-card-services {
    top: 250px;
  }

  .ty-text-description {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1440px) {
  .tip-circle-login {
    height: 30px;
    width: 60px;
  }
}

@media only screen and (min-width: 1024px) {
  .tip-circle-login {
    height: 40px;
    width: 60px;
  }
}

.tooltipCustom {
  position: relative;
  display: flex;
  background-color: #fecc17;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.tooltipCustom img {
  width: 10px;
  height: 10px;
}

.tooltipCustom .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  border-radius: 3px;
  padding: 12px;
  position: absolute;
  font-size: 12px;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border: 0.5px solid black;
}

.tooltipCustom .tooltiptext::after {
  content: "";
  position: absolute;
  border-width: 8px;
  border-style: solid;
}

.tooltipCustom.bottom .tooltiptext {
  margin-left: -60px;
  top: 150%;
  left: 50%;
}

.tooltipCustom.bottom .tooltiptext::after {
  margin-left: -5px;
  bottom: 100%;
  left: 20%;
  border-color: transparent transparent #2e2e2e transparent;
}

.tooltipCustom .tooltiptext a {
  color: #ffdd00 !important;
}

.tooltipCustom:hover .tooltiptext {
  visibility: visible;
}