@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@500&family=Orbitron:wght@500&family=Space+Grotesk:wght@700&display=swap");

:root {
  --yellow: #e8b800;
  --blueTG: #0e226d;
  --lightGray: #f5f4f4;
  --green: #59b559;
  --grey: #aca2a2;
  --lightgreey: #e7e7e8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*Remove small dot squared border*/
:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

body {
  scroll-behavior: smooth;
  font-size: 87.5%;
  /*14px*/
}

html {
  scroll-behavior: smooth;
}

/*General settings*/
h1 {
  font-family: "Roboto", sans-serif;
}

h2 {
  font-size: 1.4rem;
}

/* 
iframe{
  width:1000px; 
  height:400px;
} */

.container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* padding-top: 56.25%; */
  padding-top: 41.25%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.logo-center {
  height: 100px;
}

#html5qr-code-full-region {
  img[alt="Info icon"] {
    display: none !important;
  }
}

@import "./Css/Containers.css";
@import "./Css/Lists.css";
@import "./Css/Links.css";
@import "./Css/Animations.css";
@import "./Css/Buttons.css";
@import "./Css/Modals.css";
@import "./Css/Tables.css";
@import "./Css/Fonts.css";
