.bton {
  border: none;
  text-align: center;
  text-decoration: none;
  padding: 13px 30px;
  display: inline-block;
  border-radius: 10px;
  transition: all 0.2s;
  width: 100%;
}

.btonbilletera {
  border: none;
  text-align: center;
  text-decoration: none;
  padding: 13px 30px;
  display: inline-block;
  border-radius: 7px;
  transition: all 0.2s;
  width: auto;
  color: var(--blueTG);
  font-weight: 600;
}

.bton-link {
  transition: all 0.2s;
  text-decoration: underline;
  border: none !important;
}

.bton:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.bton:active {
  transform: translateY(-1px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/*SIZES*/
.bton-sm {
  padding: 8px 25px;
}
.bton-md {
  padding: 13px 30px;
  font-size: 1.1em;
}
.bton-lg {
  padding: 17px 34px;
  font-size: 1.2em;
}

.bton-custom {
  padding: 8px 35px;
  font-size: 1.1em;
}

/*COLORS*/
.bton-white {
  background-color: #fff;
  border: 1px solid #c9c9c9;
  color: #777;
}

.bton-yellow {
  background-color: var(--yellow);
  color: var(--blueTG);
}

.bton-disabled {
  box-shadow: none !important;
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}
