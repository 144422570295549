.container-card {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  margin-bottom: 40px;
}

.card-vias {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.1);
}

.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.description-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.description {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.description img {
  width: 25px;
  height: 25px;
}

.brake-warning {
  width: 90px;
  height: 90px;
  margin: 20px auto;
}

.container-contact {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.no-estado-vias img {
  width: 100%;
  height: 500px;
}

@media screen and (min-width: 768px) {
  .container-card {
    grid-template-columns: repeat(2, 1fr);
  }
  .container-contact {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

@media screen and (min-width: 1100px) {
  .container-contact {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}
