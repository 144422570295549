.success-message{
    background-color: #E6BA12;
    border: #E6BA12;
    font-size: 15px;
    width: 40%;
}

.success-message p{
    color: #0E295A;
}

@media only screen and (max-width: 600px) {
    .success-message{
        width: 100%;
    }
  }