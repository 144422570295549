.container-map {
    display: flex;
    /* justify-content: end; */
    justify-content: flex-end;
    align-items: center;
    margin-top: 4px;

}

.body-map {
    color: #0e226dD9;
    cursor: pointer;
}

.body-map:hover {
    color: #0e226d;
}

.body-map:hover .map-title {
    text-decoration: underline;
}